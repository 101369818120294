$transitionFast: 125ms all cubic-bezier(0.23, 1, 0.32, 1);
$transition: 500ms all cubic-bezier(0.23, 1, 0.32, 1);
$transitionSlow: 750ms all cubic-bezier(0.23, 1, 0.32, 1);
$transitionTimingCurve: cubic-bezier(0.23, 1, 0.32, 1);

$fanbelt-primary: #ff725f;
$fanbelt-primary-02: lighten($fanbelt-primary, 28%);
$fanbelt-secondary: #c9d6df;
// $fanbelt-success: #55efc4;
$fanbelt-success: #55efc4;
$fanbelt-info: #45aaf2;
// $fanbelt-danger: #ff3838;
$fanbelt-danger: #da3636;
$fanbelt-warning: #fed330;
// $fanbelt-light: #fcfcfd;
$fanbelt-light: #fff8f7;
// $fanbelt-light: #faf8ff;
$fanbelt-dark: #4e4e4e;
$fanbelt-gold: #f1c40f;
$fanbelt-muted: #6c757d;

$striped-bg: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23000000' fill-opacity='0.1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
$bg-primary-striped: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ff725f' fill-opacity='0.25' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
$striped-danger-bg: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ff3838' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
$striped-success-bg: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2355efc4' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");

$primary: $fanbelt-primary;
$secondary: $fanbelt-secondary;
$success: $fanbelt-success;
$danger: $fanbelt-danger;
$info: $fanbelt-info;
$warning: $fanbelt-warning;
$light: $fanbelt-light;
$dark: $fanbelt-dark;

$enable-gradients: false;
$spacer: 0.7rem;
$border-width: 1px;
$enable-rounded: false;
$enable-negative-margins: true;
$dropdown-link-color: $fanbelt-primary;
$dropdown-link-hover-color: $fanbelt-light;
$dropdown-link-hover-bg: $fanbelt-primary;
$form-check-input-checked-bg-color: $success;
$table-hover-bg: lighten($primary, 29);
$table-striped-bg: lighten($primary, 30.5);

$btn-hover-bg-tint-amount: 35% !default;

$min-contrast-ratio: 2 !default;

$utilities: (
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      100: 1,
    ),
  ),
);

@mixin striped {
  background-color: #ffffff;
  background-image: $striped-bg;
}
@mixin striped-primary {
  background-color: #ffffff;
  background-image: $bg-primary-striped;
}
