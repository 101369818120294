@import "../../assets/scss/vars.scss";

.wrapper {
  position: absolute;
  z-index: 99999;
  bottom: 1rem;
  right: 1rem;
  width: auto;
  height: auto;
  overflow: hidden;
}

.toast {
  background-color: white;
  position: relative;
  width: 340px;
  word-break: break-all;
  z-index: 999999;
  transform: translateY(100%);
  opacity: 0;
  transition: 550ms transform $transitionTimingCurve,
    550ms opacity $transitionTimingCurve;
  z-index: 9999;

  &.show {
    transition: 350ms transform $transitionTimingCurve,
      350ms opacity $transitionTimingCurve;
    opacity: 1;
    transform: translateY(0);
  }

  .header {
    background: $striped-bg;
    color: $primary;
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1rem;
    align-items: center;
    justify-content: space-between;
    &.success {
      background: $striped-success-bg;
      color: darken($success, 30);
    }
    &.danger {
      background: $striped-danger-bg;
      color: $danger;
    }
  }
}
