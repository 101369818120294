@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap");
body {
  // font-family: "HelveticaNeue", "Helvetica Neue", "Helvetica Neue", Helvetica, Arial,
  //   "Lucida Grande", sans-serif;
  font-family: "Source Sans Pro", sans-serif;

  // font-family: Cern, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  //   "Segoe UI Symbol";
  -webkit-font-variant-ligatures: common-ligatures;
  font-variant-ligatures: common-ligatures;
  text-rendering: optimizeLegibility;
  letter-spacing: -0.1px;
}
html,
body,
#root {
  height: 100%;
  background-color: #f6f7f9;
}

.overview-cta-section {
  height: 200px;

  &.overview-cta-section-link {
    transition: $transition;
    &:hover {
      background-color: $fanbelt-light;
    }
  }
}
.long-list {
  max-height: 300px;
  overflow: auto;
}

.bg-striped {
  @include striped;
}
.bg-primary-striped {
  @include striped-primary;
}

.sticky-top {
  background-color: rgba(246, 247, 249, 0.95);
}

.nav-pills .nav-link {
  background-color: #ffffff;
}

.vh-100-minus-nav {
  overflow: auto;
  height: 100vh;

  @media (max-width: 768px) {
    height: calc(100vh - 41px);
  }
  @media (max-width: 576px) {
    height: auto;
  }
}

.vh-100-minus-subnav {
  overflow: auto;
  height: calc(100vh - 2rem);

  // @media (max-width: 768px) {
  // }
  @media (max-width: 576px) {
    height: auto;
  }
}

.vh-100-content-list {
  overflow: auto;
  height: calc(100vh - 14rem);

  // @media (max-width: 768px) {
  // }
  @media (max-width: 576px) {
    height: auto;
  }
}

@mixin width {
  white-space: nowrap;
  padding: 1.25rem;
  // padding: 1rem 1.5rem;
}
.vh-100-nav-adjusted {
  height: calc(100vh - 60px);
}
.table-max-height {
  max-height: calc(100vh - 204px);
  overflow: auto;
}
.table-fixed-head {
  thead th {
    position: sticky;
    top: 0;
    background-color: white;
  }
}
table {
  &.table-fit {
    @include width;
    table-layout: auto !important;
    thead th,
    tfoot th {
      @include width;
    }
    tbody td,
    tfoot td {
      @include width;
    }
  }
}
.dropdown-menu {
  z-index: 99999;
}
.dropdown-menu-end {
  right: 0;
}

.remove-first-button-border {
  &:first-child {
    border-top: none !important;
  }
}
.react-date-picker {
  padding: 0;
}
.react-date-picker__wrapper {
  // border: 1px dashed $primary !important;
  // padding: 0.25rem;
  border: none !important;
  max-width: 220px;
  &:hover {
    cursor: pointer;
  }
}

.react-date-picker__inputGroup {
  input {
    max-width: 100%;
  }
  .react-date-picker__inputGroup__input {
    color: white;
  }
}

.form-control {
  position: relative;
}
.form-control + .form-icon {
  position: absolute;
  right: 0.5rem;
  // padding: 8px 27px;
  z-index: 1;
  top: calc(50% - 9px);
  color: $primary;
}

.empty-callout {
  max-width: 400px;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  // height: 100%;
  span {
    width: 100%;
    text-align: center;
  }
  img {
    width: 200px;
    height: 200px;
    // border-radius: 100%;
    // overflow: hidden;
    // border: 1px solid $secondary;
    // background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237d5fff' fill-opacity='0.25' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    // box-shadow: inset 0 0 10px $secondary;
    opacity: 0.8;
    margin-bottom: 1rem;
  }
}

.app-top-nav {
  @media (min-width: 575px) {
    height: 38px;
  }
}

.frappe-chart.chart {
  max-width: 100%;
}

.status-circle {
  width: 8px;
  height: 8px;
  border: 1px solid $info;
  border-radius: 100%;

  &.unread {
    background-color: $info;
  }
}

.social-badge {
  width: 30px;
  height: 30px;
}

.social-wrapper {
  max-width: 180px;
}

.bg-linkedin {
  background-color: #0072b1;
}

.bg-twitter {
  background-color: #1da1f2;
}

.bg-facebook {
  background-color: #3b5998;
}

.bg-instagram {
  background-color: #e1306c;
}

.bg-youtube {
  background-color: #ff0000;
}

.deletable-card {
  $deleteButtonSize: 40px;
  position: relative;
  border-bottom-width: 2px;

  .expandable-card-delete-button {
    color: $danger;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 250ms transform $transitionTimingCurve, 250ms box-shadow $transitionTimingCurve;
    transform: translate3d(0, 0, 0);
    transition-delay: 0;
    z-index: -1;
    width: $deleteButtonSize;
    height: 100%;
    border: 1px solid $danger;
    position: absolute;
    right: 0;
    top: 0;
    background-color: white;
    background-image: $striped-danger-bg;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0.25rem 0.5rem $secondary;
    }
  }

  &:hover {
    transition-delay: 500ms;
    .expandable-card-delete-button {
      z-index: 1;
      transform: translate3d(4px, -4px, 0);
    }
  }
}

.profile-image-col {
  margin-top: -3rem;
}
.profile-image-wrapper {
  width: 180px;
  height: 180px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid $light;
  background: $bg-primary-striped;

  img {
    background: white;
    object-fit: cover;
    max-width: 80%;
    height: 80%;
  }
}

.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner-wrapper {
    position: absolute;
    z-index: 0;
  }

  .firebaseui-wrapper {
    z-index: 1;
    background-color: #f6f7f9;
  }
}

.form-check {
  .form-check-input-lg {
    width: 1.5em;
    height: 1.5em;
    margin-top: 0;
    border-radius: 0.25rem;
  }
  &.form-switch {
    .form-check-input-lg {
      width: 3em;
      height: 1.5em;
      margin-top: 0;
    }
  }
}

.form-section-row {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.card-header {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.tooltip {
  max-width: 240px;
  opacity: 1 !important;
}

.w-20 {
  width: 20% !important;
}

.nav-button {
  &:hover {
    background-color: $fanbelt-primary-02;
  }
}

.table-cell-profile-image {
  border-radius: 100%;
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-width: 2px !important;
  img {
    max-height: 100%;
  }
}

.table-responsive {
  max-height: 500px;
}

.react-colorful {
  width: 120px !important;
  height: 120px !important;
  margin-right: 0.7rem !important;
}
